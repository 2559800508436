import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

const IconPackage = {
  solid: 'fas',
  regular: 'far',
  light: 'fal',
} as const;
type IconPackage = keyof typeof IconPackage;

const IconSize = {
  '2xs': 'fa-2xs',
  xs: 'fa-xs',
  sm: 'fa-sm',
  none: '',
  lg: 'fa-lg',
  xl: 'fa-xl',
  '2xl': 'fa-2xl',
  '3xl': 'fa-3xl',
  '4xl': 'fa-4xl',
  '5xl': 'fa-5xl',
  '6xl': 'fa-6xl',
  '7xl': 'fa-7xl',
  '8xl': 'fa-8xl',
  '9xl': 'fa-9xl',
} as const;
type IconSize = keyof typeof IconSize;

const IconAnimation = {
  none: '',
  beat: 'fa-beat',
  'beat-fade': 'fa-beat-fade',
  bounce: 'fa-bounce',
  fade: 'fa-fade',
  flip: 'fa-flip',
  shake: 'fa-shake',
  spin: 'fa-spin',
  'spin-reverse': 'fa-spin-reverse',
  'spin-pulse': 'fa-spin-pulse',
} as const;
type IconAnimation = keyof typeof IconAnimation;

const IconRotation = {
  none: '',
  rotate90: 'fa-rotate-90',
  rotate180: 'fa-rotate-180',
  rotate270: 'fa-rotate-270',
} as const;
type IconRotation = keyof typeof IconRotation;

const IconFlip = {
  none: '',
  horizontal: 'fa-flip-horizontal',
  vertical: 'fa-flip-vertical',
  both: 'fa-flip-both',
} as const;
type IconFlip = keyof typeof IconFlip;

type FlexibleInput<T> = T | T[];

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  template: `
    <ng-container *ngIf="!isStacked; else stackedTemplate">
      <fa-icon [icon]="[getPackageAsPrefix(0), getIcon(0)]" [class]="combinedClasses"> </fa-icon>
    </ng-container>
    <ng-template #stackedTemplate>
      <fa-stack class="fa-stack" [class]="stackClass">
        <fa-icon
          *ngFor="let _ of getIconsArray(); let i = index"
          [icon]="[getPackageAsPrefix(i), getIcon(i)]"
          [class]="getIconClass(i)"
          [stackItemSize]="i === 0 ? '2x' : '1x'"
        >
        </fa-icon>
      </fa-stack>
    </ng-template>
  `,
  styles: [
    `
      fa-stack {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
      }
      app-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class IconComponent implements OnInit {
  @Input() icon: FlexibleInput<IconName> = 'notdef';
  @Input() package: FlexibleInput<IconPackage> = 'regular';
  @Input() iconSize: FlexibleInput<IconSize> = 'none';
  @Input() fixedWidth: boolean = false;
  @Input() iconClass: FlexibleInput<string> = '';
  @Input() animation: FlexibleInput<IconAnimation> = 'none';
  @Input() rotation: FlexibleInput<IconRotation> = 'none';
  @Input() flip: FlexibleInput<IconFlip> = 'none';
  
  @Input() stackItemSize: FlexibleInput<string> = '2x';
  @Input() stackClass: string = '';
  isStacked: boolean = false;

  @HostBinding('class') hostClass = '';

  ngOnInit(): void {
    this.isStacked = Array.isArray(this.icon) && this.icon.length > 1;
  }

  private ensureArray<T>(value: FlexibleInput<T>): T[] {
    return Array.isArray(value) ? value : [value];
  }

  getIconsArray(): IconName[] {
    return this.ensureArray(this.icon);
  }

  getIcon(index: number): IconName {
    const icons = this.ensureArray(this.icon);
    return icons[index] || icons[0] || 'notdef';
  }

  getPackage(index: number): IconPackage {
    const packages = this.ensureArray(this.package);
    const packageValue = packages[index] || packages[0] || 'far';
    return packageValue as IconPackage;
  }

  getPackageAsPrefix(index: number): IconPrefix {
    const packageValue = this.getPackage(index);
    switch (packageValue) {
      case 'solid':
        return 'fas';
      case 'regular':
        return 'far';
      case 'light':
        return 'fal';
      default:
        return 'far';
    }
  }

  get combinedClasses(): string {
    return `${this.hostClass} ${this.getIconClass(0)}`.trim();
  }

  getIconClass(index: number): string {
    const animations = this.ensureArray(this.animation);
    const sizes = this.ensureArray(this.iconSize);
    const rotations = this.ensureArray(this.rotation);
    const flips = this.ensureArray(this.flip);
    const classes = this.ensureArray(this.iconClass);

    const animation = animations[index] || animations[0] || 'none';
    const size = sizes[index] || sizes[0] || 'none';
    const rotation = rotations[index] || rotations[0] || 'none';
    const flip = flips[index] || flips[0] || 'none';

    let classString = IconSize[size];

    if (animation !== 'none') {
      classString += ' ' + IconAnimation[animation];
    } else {
      if (rotation !== 'none') {
        classString += ' ' + IconRotation[rotation];
      } else if (flip !== 'none') {
        classString += ' ' + IconFlip[flip];
      }
    }

    if (this.fixedWidth) {
      classString += ' fa-fw';
    }

    return classString.trim() + ' ' + (classes[index] || classes[0] || '').trim();
  }
}
